import React from 'react';
import { useFonts } from 'expo-font';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from '@components/Home';
import PollContainer from '@components/PollContainer';
import MockFavorites from '@components/MockFavorites';

const Stack = createNativeStackNavigator();

export default function App() {

  const [loaded] = useFonts({
    sfProDisplayRegular: require('@assets/fonts/SFProDisplay-Regular.ttf'),
    sfProDisplaySemibold: require('@assets/fonts/SFProDisplay-Semibold.ttf'),
    sfProDisplayBold: require('@assets/fonts/SFProDisplay-Bold.ttf'),
  });

  if (!loaded) {
    return null;
  }

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}
      >
        <Stack.Screen name="Home" component={MockFavorites} />
        <Stack.Screen name="Poll" component={PollContainer} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

