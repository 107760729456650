import * as PollSDK from "@sdk/CreatePoll"
import { appEnv } from '@src/manifest'

export const createPoll = async (poll: PollSDK.Poll) => {

    // if you use a different api key you need to update the api base url
    // staging demo api key: K66U1eWgUp7dcmPQ9ORZo6PDzvpElOQya9lZBx43
    // int demo api key: 

    const env = appEnv()
    interface EnvConfig {
        apiBaseUrl: string
        apiKey: string
    }
    const envConfigs: Record<string, EnvConfig> = {
        dev: {
            apiBaseUrl: 'https://dev.polls-api.com/partner/v1',
            apiKey: 'lkPmcLppbr23pAV7V92a5d6DwVU08rsampbFJvEj',
        },
        staging: {
            apiBaseUrl: 'https://staging.polls-api.com/partner/v1',
            apiKey: 'K66U1eWgUp7dcmPQ9ORZo6PDzvpElOQya9lZBx43',
        },
        prod: {
            apiBaseUrl: 'https://polls-api.com/partner/v1',
            apiKey: 'iSSKPmkrX07yePanHbtWb7BFqzBuEG2Uah999FKM',
        },

    }

    const envConfig = envConfigs[env] ?? envConfigs.prod

    const pollUrl = await PollSDK.createPoll({
        apiBaseUrl: envConfig.apiBaseUrl,
        apiKey: envConfig.apiKey,
        poll: poll
    })
    return pollUrl

}