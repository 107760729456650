import Constants from "expo-constants"

const manifest = Constants.manifest2 ?? Constants.manifest

const getManifestValueWithFallback = (name: string, fallback: string) => {
    const value =
        Constants.manifest2?.extra?.expoClient?.extra?.eas?.[name] ??
        Constants.manifest?.extra?.eas?.[name]

    return value ?? fallback
}

export const appEnv = () => {
    return getManifestValueWithFallback("app_env", "staging")
}
