import React from 'react';
import { StyleSheet, View } from 'react-native';
import { WebView } from 'react-native-webview';

export default function PollContainer({ route }) {

  const styles = createStyles({})

  return (
    <View style={styles.container}>
      <WebView
        style={styles.webView}
        source={{ uri: route.params.pollUrl}}
      />
    </View>
  );
}

interface AppStyleProps {

}

const createStyles = ({ }: AppStyleProps) => StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'blue',
  },
  webView: {
    width: '100%',
    height: '100%',
  }
});
