import * as PollSDK from "@sdk/CreatePoll";
import { ImageSourcePropType } from "react-native";

export interface Demo {
  pollUrl: string,
  overlayImage: ImageSourcePropType,
  screenshotImage: ImageSourcePropType,
  screenshotSize: {
    width: number,
    height: number,
  }
  createPollButtonPosition: {
    top: number,
    left: number,
    width: number,
    height: number,
  },
  ctaPosition: {
    top: number,
    left: number,
    width: number,
    height: number,
  }
  poll: PollSDK.Poll & { customData: { theme: string } };
}

const demos: { [key: string]: Demo } = {
  pinktada: {
    pollUrl: 'https://demo.pollsplatform.com/polls/95adbaad-608e-4cec-86bb-bb1920e05984?theme=pinktada',
    overlayImage: require("@partners/pinktada/overlay.png"),
    screenshotImage: require("@partners/pinktada/screenshot.png"),
    screenshotSize: {
      width: 1676 * 2,
      height: 914 * 2,
    },
    createPollButtonPosition: {
      top: 169 * 2,
      left: 481 * 2,
      width: 143 * 2,
      height: 27 * 2,
    },
    ctaPosition: {
      top: 538 * 2,
      left: 791 * 2,
      width: 162 * 2,
      height: 44 * 2,
    },
    poll: {
      settings: {
        multipleVotes: true,
        postVoteAction: PollSDK.PostVoteAction.shareVote
      },
      title: "Which hotel should we book for the trip?",
      ownerId: "2616863f-b9e4-4e89-97d1-af6065045e7a",
      options: [
        {
          url: "https://www.pinktada.com/hotel/waikiki-beachcomber-by-outrigger/38?dates=2023-08-01,2023-08-03&pub_MaxPeople=2,&a=2&t=0&c=0",
          imageUrl:
            "https://pinktadainc.imgix.net/Property_38/Room_Default_Exterior_0/Default_Exterior_0.jpg?fit=crop&h=502&w=870",
          type: "defaultTemplate",
          title: "Waikiki Beachcomber by Outrigger",
          subtitle: "4.2 Very good (1745 Reviews)",
          details: "Honolulu, Hawaii",
        },
        {
          url: "https://www.pinktada.com/hotel/outrigger-kona-resort-and-spa/43?dates=2023-08-01,2023-08-03&pub_MaxPeople=2,&a=2&t=0&c=0",
          imageUrl:
            "https://pinktadainc.imgix.net/Property_43/Room_Default_Exterior_0/Default_Exterior_0.jpg?fit=crop&h=502&w=870",
          type: "defaultTemplate",
          title: "Outrigger Kona Resort and Spa",
          subtitle: "3.8 Very good (19 Reviews)",
          details: "Kailua-Kona, Hawaii",
        },
        {
          url: "https://www.pinktada.com/hotel/outrigger-reef-waikiki-beach-resort/36?dates=2023-08-01,2023-08-03&pub_MaxPeople=2,&a=2&t=0&c=0",
          imageUrl:
            "https://pinktadainc.imgix.net/Property_36/Room_Default_Exterior_0/Default_Exterior_0.jpg?fit=crop&h=502&w=870",
          type: "defaultTemplate",
          title: "Outrigger Reef Waikiki Beach Resort",
          subtitle: "4.4 Very good (3707 Reviews)",
          details: "Honolulu, Hawaii",
        },
      ],
      customData: {
        theme: "pinktada",
      },
    },
  },
  seated: {
    pollUrl: 'https://demo.pollsplatform.com/polls/28607ba9-07bd-4b7c-8f28-56a00440599c?theme=seated',
    overlayImage: require("@partners/seated/overlay.png"),
    screenshotImage: require("@partners/seated/screenshot.png"),
    screenshotSize: {
      width: 3328,
      height: 1828,
    },
    createPollButtonPosition: {
      top: 282.5 * 2,
      left: 276.5 * 2,
      width: 181 * 2,
      height: 45 * 2,
    },
    ctaPosition: {
      top: 629 * 2,
      left: 634 * 2,
      width: 181 * 2,
      height: 45 * 2,
    },
    poll: {
      settings: {
        multipleVotes: true,
        postVoteAction: PollSDK.PostVoteAction.shareVote
      },
      title: "Where should we go for dinner?",
      ownerId: "2616863f-b9e4-4e89-97d1-af6065045e7a",
      options: [{
        type: "defaultTemplate",
        title: 'Westville Hell’s Kitchen',
        subtitle: '$$ | American, Local Favorite, Breakfast & Brunch, Outdoor Heating',
        details: '⭐️⭐️⭐️⭐️',
        url: 'https://vote.pollsapp.io/vygied',
        imageUrl: 'https://i.imgur.com/AdlaRfQ.jpg'
      },
      {
        type: "defaultTemplate",
        title: 'Porter House Bar and Grill',
        subtitle: '$$$$ | Trendy, American, Cocktails',
        details: '⭐️⭐️⭐️',
        url: 'https://vote.pollsapp.io/t30xe8',
        imageUrl: 'https://i.imgur.com/OEN1vtB.jpg'
      },
      {
        type: "defaultTemplate",
        title: 'Hide-Chan Ramen, West Side',
        subtitle: '$$$ | Extensive Wine List, Upscale',
        details: '⭐️⭐️⭐️⭐️',
        url: 'https://vote.pollsapp.io/fyigey',
        imageUrl: 'https://i.imgur.com/fCvDKmu.jpg'
      }
      ],
      customData: {
        theme: "seated",
      },
    },
  },

  turo: {
    pollUrl: 'https://demo.pollsplatform.com/polls/d4bf5bf3-c7eb-43ef-9364-a41a054cd260?theme=turo',
    overlayImage: require("@partners/turo/overlay.png"),
    screenshotImage: require("@partners/turo/screenshot.png"),
    screenshotSize: {
      width: 1676 * 2,
      height: 914 * 2,
    },
    createPollButtonPosition: {
      top: 124 * 2,
      left: 1019 * 2,
      width: 145 * 2,
      height: 34 * 2,
    },
    ctaPosition: {
      top: 523 * 2,
      left: 741 * 2,
      width: 156 * 2,
      height: 35 * 2,
    },
    poll: {
      settings: {
        multipleVotes: true,
        postVoteAction: PollSDK.PostVoteAction.shareVote
      },
      title: "Which car should we rent?",
      ownerId: "2616863f-b9e4-4e89-97d1-af6065045e7a",
      options: [{
        type: "defaultTemplate",
        title: 'Mercedes-benz S-Class 2019',
        subtitle: 'Maybach S560 4Matic',
        details: '$167 / day',
        url: 'https://turo.com/us/en/car-rental/united-states/glendale-ca/mercedes-benz/s-class/1448801',
        imageUrl: 'https://i.imgur.com/4RWPHw7.jpeg'
      },
      {
        type: "defaultTemplate",
        title: 'Porsche Panamera 2017 E-Hybrid S',
        subtitle: '4S Executive',
        details: '$175 / day',
        url: 'https://turo.com/us/en/car-rental/united-states/los-angeles-ca/porsche/panamera/1407513',
        imageUrl: 'https://i.imgur.com/QmTEmZk.jpeg'
      },
      {
        type: "defaultTemplate",
        title: 'Tesla Model S 2021 White 4D',
        subtitle: 'Plaid',
        details: '$189 / day',
        url: 'https://turo.com/us/en/car-rental/united-states/los-angeles-ca/tesla/model-s/1251013',
        imageUrl: 'https://i.imgur.com/RpJHej9.jpg'
      }
      ],
      customData: {
        theme: "turo",
      },
    },
  },

  udrive: {
    pollUrl: '', // TODO: ❌
    overlayImage: require("@partners/turo/overlay.png"), // TODO: ❌
    screenshotImage: require("@partners/turo/screenshot.png"), // TODO: ❌
    screenshotSize: {
      width: 1676 * 2,
      height: 914 * 2,
    },
    createPollButtonPosition: {
      top: 124 * 2,
      left: 1019 * 2,
      width: 145 * 2,
      height: 34 * 2,
    },
    ctaPosition: {
      top: 523 * 2,
      left: 741 * 2,
      width: 156 * 2,
      height: 35 * 2,
    },
    poll: {
      settings: {
        multipleVotes: true,
        postVoteAction: PollSDK.PostVoteAction.shareVote
      },
      title: "Which car should we rent?",
      ownerId: "2616863f-b9e4-4e89-97d1-af6065045e7a",
      options: [{
        type: "defaultTemplate",
        title: 'Mitsubishi Attrage \nDaily Rental',
        subtitle: '119 AED per day',
        details: 'Per Trip KM: 100 kms | Fuel: 39%',
        url: 'https://vote.pollsapp.io/igto7b',
        imageUrl: 'https://i.imgur.com/CJNJAMl.png'
      },
      {
        type: "defaultTemplate",
        title: 'Toyota Camry \nDaily Rental',
        subtitle: '189 AED per day',
        details: 'Per Trip KM: 150 kms | Fuel: 85%',
        url: 'https://vote.pollsapp.io/gmkb8h',
        imageUrl: 'https://i.imgur.com/PzRFHrS.png'
      },
      {
        type: "defaultTemplate",
        title: 'Jeep Wrangler \nDaily Rental',
        subtitle: '375 AED per day',
        details: 'Per Trip KM: 150 kms | Fuel: 30%',
        url: 'https://vote.pollsapp.io/gejswx',
        imageUrl: 'https://i.imgur.com/6WIyZsp.png'
      }
      ],
      customData: {
        theme: "udrive",
      },
    },
  },

};

export const getDemo = (key: string) => {
  return demos[key] ?? demos.seated;
};