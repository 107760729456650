import axios from 'axios';

export enum PostVoteAction {
    none = 'none', // if set to none, after voting users will simply see a confirmation check mark
    shareVote = 'shareVote' // if set to sharevote, after voting users will be prompted to share their vote back to the group
}

export interface Poll {
    ownerId: string, // identifier for the user that is creating the poll
    settings: {
        multipleVotes: boolean, // if true, then users can vote for more than one option in the list
        postVoteAction: PostVoteAction // see enum description above
    },
    title: string, // title of the poll, 1-500 characters, UI will truncate text with ellipses if it doesn't fit
    options: Option[] // array of options
    customData?: any // reach out to Polls Platform Support if you need to add custom fields
};

export interface Option {
    type: 'defaultTemplate', // defines the fields and visual layout of the option cards
    title: string, // title of the option, 1-500 characters, UI will truncate text with ellipses if it doesn't fit
    subtitle?: string, // 0-500 characters, UI will truncate text with ellipses if it doesn't fit
    details?: string, // 0-500 characters, UI will truncate text with ellipses if it doesn't fit
    imageUrl: string, // URL for thumbnail image, CORS policy must allow us to load the image from our domain
    url: string, // URL to navigate to when users tap "view details" on this option
};

export const createPoll = async (options: {
    apiBaseUrl: string, // e.g. https://int.polls-api.com/partner/v1
    apiKey: string,
    poll: Poll,
}) => {

    const {
        apiBaseUrl,
        apiKey,
        poll
    } = options

    var data = JSON.stringify({ poll: poll });

    var request = {
        method: 'post',
        url: `${apiBaseUrl}/polls`,
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        const { data } = await axios(request);
        const pollUrl = data?.poll?.url;

        // open the poll URL in a new tab
        // the polls platform takes care of everything from there

        return pollUrl

    } catch (error) {
        console.log(error);
    }

};