import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Image, Platform, Pressable, Text, Animated } from 'react-native';
import { Dimensions } from 'react-native';
import { getDemo } from '@src/demos'
import { createPoll } from '@src/createPoll';
import LottieAnimation from './LottieAnimation';

const windowDimensions = Dimensions.get('window');
const screenDimensions = Dimensions.get('screen');

export default function MockFavorites({ navigation }) {
  const [dimensions, setDimensions] = React.useState({ windowDimensions, screenDimensions });
  const [shouldShowOverlay, setShouldShowOverlay] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isDonePreloading, setIsDonePreloading] = React.useState(false)

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
      setDimensions({ windowDimensions: window, screenDimensions: screen });
      console.log(window.width)
    });
    return () => subscription?.remove();
  });

  const fadeDuration = 250
  const screenshotFadeAnim = React.useRef(new Animated.Value(0)).current
  const overlayFadeAnim = React.useRef(new Animated.Value(0)).current

  React.useEffect(() => {

    if (!isDonePreloading) { return }

    Animated.timing(
      screenshotFadeAnim,
      {
        toValue: 1,
        duration: fadeDuration,
        useNativeDriver: false
      },
    ).start();

    setTimeout(() => {
      Animated.timing(
        overlayFadeAnim,
        {
          toValue: 1,
          duration: fadeDuration,
          useNativeDriver: false
        },
      ).start();
    }, 1500);
  }, [isDonePreloading])

  const isMobile = dimensions.windowDimensions.width < 800
  const styles = createStyles({ isMobile })

  const isWeb = Platform.select({ web: true, default: false })

  let demoKey: string = 'seated'
  if (isWeb) {
    const queryParams = new URLSearchParams(window.location.search)
    demoKey = queryParams.get('demo') ?? ''
  }

  const demo = getDemo(demoKey)

  if (isMobile) {
    window.location.href = demo.pollUrl;
  }

  const didPressCreatePoll = async () => {

    Animated.timing(
      overlayFadeAnim,
      {
        toValue: 0,
        duration: 0,
        useNativeDriver: false
      },
    ).start();

    setIsLoading(true);
    const pollUrl = await createPoll(demo.poll)

    if (!pollUrl) { return }

    const isNative = Platform.select({ native: true, default: false })

    if (isNative) {
      navigation.navigate('Poll', { pollUrl })
    } else if (isMobile) {
      window.location.href = pollUrl;
    } else {
      window.open(pollUrl, '_blank');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    Animated.timing(
      overlayFadeAnim,
      {
        toValue: 1,
        duration: 0,
        useNativeDriver: false,
        delay: 1000
      },
    ).start();

  }

  const didFinishLoadingScreenshot = () => {
    setIsDonePreloading(true)
  }

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      <Animated.View style={{
        position: 'absolute',
        width: '100%',
        height: demo.screenshotSize.height / demo.screenshotSize.width * dimensions.windowDimensions.width,
        opacity: screenshotFadeAnim
      }}>
        <Image
          style={styles.screenshot}
          source={demo.screenshotImage}
          onLoadEnd={didFinishLoadingScreenshot}
        />
      </Animated.View>
      <Animated.View style={{
        position: 'absolute',
        width: '100%',
        height: demo.screenshotSize.height / demo.screenshotSize.width * dimensions.windowDimensions.width,
        opacity: overlayFadeAnim
      }}>
        <Image
          style={styles.screenshot}
          source={demo.overlayImage}
        />
      </Animated.View>
      <Pressable
        style={{
          // backgroundColor: 'green',
          position: 'absolute',
          top: dimensions.windowDimensions.width / demo.screenshotSize.width * demo.ctaPosition.top,
          left: dimensions.windowDimensions.width / demo.screenshotSize.width * demo.ctaPosition.left,
          width: dimensions.windowDimensions.width / demo.screenshotSize.width * demo.ctaPosition.width,
          height: dimensions.windowDimensions.width / demo.screenshotSize.width * demo.ctaPosition.height
        }}
        onPress={didPressCreatePoll}
      />
      <Pressable
        style={{
          // backgroundColor: 'green',
          position: 'absolute',
          top: dimensions.windowDimensions.width / demo.screenshotSize.width * demo.createPollButtonPosition.top,
          left: dimensions.windowDimensions.width / demo.screenshotSize.width * demo.createPollButtonPosition.left,
          width: dimensions.windowDimensions.width / demo.screenshotSize.width * demo.createPollButtonPosition.width,
          height: dimensions.windowDimensions.width / demo.screenshotSize.width * demo.createPollButtonPosition.height
        }}
        onPress={didPressCreatePoll}
      />
      {
        isLoading &&
        <View style={styles.loading}>
          <View style={styles.loadingModal}>
            <LottieAnimation
              style={styles.spinner}
              source={require('@assets/animations/spinner.json')}
              autoPlay
              loop
            />
          </View>
        </View>
      }
    </View >
  );
}

interface AppStyleProps {
  isMobile: boolean
}

const createStyles = ({ isMobile }: AppStyleProps) => StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: 'blue'
  },
  screenshot: {
    width: '100%',
    height: '100%',
    resizeMode: "contain"
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingModal: {
    width: 160,
    height: 160,
    backgroundColor: 'white',
    borderRadius: 20,
    boxShadow: "0px 0px 7px rgba(0,0,0,0.25)",
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinner: {
    width: 80,
    height: 80
  }
});
